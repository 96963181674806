import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import { useParams } from "react-router";
import Footer from "./Footer";
import { db } from "../Firebase";
import { DataProvider } from "./DataContext";



const ShowTreatment = () => {
    const { id } = useParams();
    const [fetchedData, setFetchedData] = useState({});
    const [imgData, setImgData] = useState([]);
    const dbRef = db.collection('/treatments/').doc(id);
    const dbRefImg = db.collection('/treatments/' + id + '/imgs');



    useEffect(() => {


        window.scrollTo(0, 0);

        const getDataImgs = async () => {
            const data = await dbRefImg.get()
            //  .where("t_id", "==", props.id).
            const items = data.docs;
            setImgData(items);
        }

 
        const getData = async () => {

            dbRef.get().then((doc) => {
                if (doc.exists) {
                    // console.log("Document data:", doc.data());
                    setFetchedData(doc.data())
                } else {
                    // doc.data() will be undefined in this case
                    // console.log("No such document!");
                }
            }).catch((error) => {
                // console.log("Error getting document:", error);
            });
        }
        getDataImgs();
        getData();
    }, [])



    return (
        <>
            <DataProvider>
                <NavBar />

                <div className="container p-0">

                    <div className="row m-0 justify-content-center">
                        <div className="section-title">
                            <span>Treatment</span>
                            <h2>{fetchedData.title}</h2>
                            <div className="row mt-4 img-thumbnail mb-4 doctor-head">
                                 <div className="col-lg-8 pt-4">
                                    <center>

                                        <h3 className="text-dark"> About {fetchedData.title} </h3>
                                        <p className="mt-4 text-dark editorS" style={{textAlign:'left'}}>{fetchedData.description}</p>

                                    </center>
                                </div>
                                <div className="col-lg-4"><img className="w-100 photo-card" src={fetchedData.imgUrl} alt="..." style={{ borderRadius: '2px' }} /> 
                                {
                             imgData.map(item => {
                                            let data = item.data();
                                            return (
                                            
                                                <div className="photo-card mt-2">
                                                <img className="w-100 " src={data.url}  alt="..."/>
                                            
                                            </div>
                                        )
                                })}
                                
                                </div>
                               
                            </div>
                            <div className="row">
                           
                            </div>
                        </div>
                    </div>


                </div>


                <Footer />
            </DataProvider>
        </>
    )
}
export default ShowTreatment;