import React, { useEffect, useState } from "react";
import { FaArrowRight, FaUserMd } from "react-icons/fa";
import { Link } from "react-router-dom";
import { storageRef, db } from "../Firebase";
import Sprator from "./Sprator";
import { useData } from "./DataContext";


const Mission = (props) => {
    const { home } = useData();
    const [fetchedData, setFetchedData] = useState([]);
    const dbRef = db.collection('/treatments');
    useEffect(() => {


        const getData = async () => {
            const data = await dbRef.get();

            const items = data.docs;
            setFetchedData(items);

         
           
        }
        getData();
    }, [])


    return (
        <section className="our-mission-area pb-4 pt-4">
             <p className="pt-4 pb-4 mt-4"></p>
            <div className="container">
                <div className="row m-0">
                    <div className="col-lg-6">
                        <div className="our-mission-content">
                            <h2>{home.treatmentHeading}</h2>
                            <p>{home.treatmentDes}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 p-0">
                        <div className="row treatments" >

                            {
                                fetchedData.map((item, idx) => {
                                    let data = item.data();
                                    if (props.num) {
                                        if (idx < props.num)
                                            return (
                                                <div className="col-lg-6 p-2" >
                                                    <div className="img-thumbnail p-2" >
                                                        <h5>{data.title}</h5>
                                                        <Sprator />
                                                        <span className=""> {data.description.substring(0, 100)}... </span><br />
                                                        <Link className="btn text-primary" to={"/show-treatment/" + item.id}>  Learn More <FaArrowRight /> </Link>
                                                    </div>
                                                </div>
                                            )
                                    }
                                    else
                                        return (
                                            <div className="col-lg-6 p-2" >
                                                <div className="img-thumbnail p-2" >
                                                    <h5>{data.title}</h5>
                                                    <Sprator />
                                                    <span > {data.description.substring(0, 100)}... </span>
                                                    <Link className="btn text-primary" to={"/show-treatment/" + item.id}> Learn More <FaArrowRight /> </Link>
                                                </div>
                                            </div>
                                        )
                                })}


                        </div>
                        {(props.num) && <Link className="photo-link btn btn-primary mt-4" to="/treatments"  > See All Treatments <i><FaArrowRight /></i></Link>}
                    </div>
                </div>
            </div>
            <p className="pt-4 pb-4 mt-4"></p>
        </section>
     
    )
}

export default Mission;