import React, { useState, useEffect } from "react";
import DashboardNav from "./DashboardNav";
import { storageRef, db } from "../Firebase";
import SideBar from "./SideBar";
import { FaEdit, FaTrash } from "react-icons/fa";


const DoctorManagement = () => {
    const [file, setFile] = useState('');
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState('');
    const [url, setUrl] = useState('');
    const [tempUrl, setTempUrl] = useState('');
    const imgRef = storageRef.ref('Images/' + file.name + '_' + new Date());
    const [fetchedData, setFetchedData] = useState([]);
    const [trigger, setTrigger] = useState(true);
    // const [header,setHeader] =useState([]);
    const [input, setInput] = useState({ description: '', imgUrl: '', name: '', qualification: '', title: '', type: '' });
    const [currentId, setCurrentId] = useState('');
    const [loading, setLoading] = useState(false);
    const dbRef = db.collection('/doctors');

    const handleChangeImg = async (event) => {
        setFile(event.target.files[0]);
    }
    const handleUpload = async () => {
        imgRef.put(file).on('state_changed', (snap) => {
            let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
            setProgress(percentage);
        }, (err) => {
            setError(err);
        }, async () => {
            const urlim = await imgRef.getDownloadURL();
            // const createdAt = new Date();

            setProgress(0)
            setUrl(urlim);
            setInput(prev => ({ ...prev, imgUrl: urlim }));

        });
    }


    useEffect(() => {

        setInput(prev => ({ ...prev, type: "medical" }))
        const getData = async () => {
            const data = await dbRef.orderBy("order","asc",).get();

            const items = data.docs;
            setFetchedData(items);
        }
        getData();
    }, [trigger])

    const saveChanges = async () => {
        setLoading(true)
        if (tempUrl) {
            const file = storageRef.refFromURL(tempUrl);
            const deleted = file.delete();
            const updated = dbRef.doc(currentId).update(input)
            await Promise.all([deleted, updated])
            setTrigger(!trigger)
            setLoading(false)
            setCurrentId('')
            setTempUrl('')
            setInput({ description: '', imgUrl: '', name: '', qualification: '', title: '', type: 'medical' })
            setUrl('')
        }
        else {
            await dbRef.doc(currentId).update(input)
            setTrigger(!trigger)
            setLoading(false)
            setCurrentId('')
            setInput({ description: '', imgUrl: '', name: '', qualification: '', title: '', type: 'medical' })
            setUrl('')
        }


    }
    const handleCancel = () => {
        setInput({ description: '', imgUrl: '', name: '', qualification: '', title: '', type: 'medical' })
        setCurrentId('')
    }
    const getDataForUp = (id, data) => {
        setCurrentId(id);
        setInput(data)
    }

    const postData = async () => {
        setLoading(true)

        const err = await dbRef.add(input);
        if (!err) {
            console.log(err);
        }
        else {
            setTrigger(!trigger);
            setInput({});
            setLoading(false)
            setUrl('')
        }

    }
    const handleRemoveImg = async (url) => {
        setTempUrl(url);
        setFile('')
        setInput(prev => ({ ...prev, imgUrl: '' }));

    }
    const handleDelete = async (id, url) => {
        setCurrentId('')

        if (url) {
            const file = storageRef.refFromURL(url);
            const deletedImg = await file.delete();
            const deletedData = db.collection('doctors').doc(id).delete();
            if (await Promise.all([deletedImg, deletedData]))
                setTrigger(!trigger)
        } else {
            setError('It does not have any image please edit and upload a temporary imgage and then try deleting')
        }


    }

    const handleCancelImg = () => {
        setUrl('');
        setInput({});
    }


    const handleChange = (event) => {
        setInput(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }
    return (
        <>
            <DashboardNav />
            <div className="container-fluid bg-light" >
                <div className="row dash-row" >
                    <SideBar />
                    <div className="col-lg-10 justify-content-md-center p-4">
                        <div className="row   ">
                            {(!currentId) && <div className="img-thumbnail col-lg-3 p-2" >
                                <h5 className="text-primary"> Add Staff Member </h5>


                                {
                                    (url) && <img src={url} style={{ maxWidth: '100%' }} alt="..." />
                                }
                                {(progress !== 0) &&
                                    <div className="progress mb-2">
                                        <div className="progress-bar" style={{ width: progress + '%' }}  ></div>
                                    </div>
                                }
                                {(!url) && <div className="form-group" >
                                    <label className="pb-2">Select Image</label>
                                    <input className="form-control text-center" type="file" accept="image/*" capture="camera" onChange={handleChangeImg} />

                                    {(file.name) && <button className="btn btn-success mt-4 w-100 text-center" onClick={handleUpload} >upload</button>}

                                </div>
                                }
                                {(url) && <button className="btn-danger btn text-center mt-2" onClick={handleCancelImg} >Cancel</button>}

                                {(url) && (<>
                                    <div className="form-group" >
                                        <label className="pb-2"> Order Number</label>
                                        <input className="form-control text-center" value={input.order} onChange={handleChange} name="order" />
                                    </div>
                                    <div className="form-group" >
                                        <label className="pb-2"> Name</label>
                                        <input className="form-control text-center" value={input.name} onChange={handleChange} name="name" />
                                    </div>
                                    <div className="form-group" >
                                        <label className="pb-2"> Staff type</label>
                                        <select className="form-control text-center" value={input.type} onChange={handleChange} name="type" >
                                            <option value="medical">medical</option>
                                            <option value="administration" selected>administration</option>
                                        </select>
                                    </div>
                                    <div className="form-group" >
                                        <label className="pb-2"> Title</label>
                                        <input className="form-control text-center" value={input.title} onChange={handleChange} name="title" />
                                    </div>
                                    <div className="form-group" >
                                        <label className="pb-2"> Doctor's Quaification</label>
                                        <input className="form-control text-center" value={input.quaification} onChange={handleChange} name="qualification" />
                                    </div>
                                    <div className="form-group" >
                                        <label className="pb-2"> Doctor's Description</label>
                                        <textarea className="form-control text-center" value={input.description} onChange={handleChange} name="description" />
                                    </div>





                                    <div className="form-group" >
                                        <center>
                                            <button disabled={(loading) ? 'disabled' : ''} className="btn btn-success mt-4 w-100 text-center" onClick={() => { postData() }}>{(loading) ? 'Saving...' : 'Save Changes'}</button>
                                        </center>
                                    </div>
                                </>)}
                            </div>

                            }

                            {(currentId) && <div className="img-thumbnail col-lg-3 p-2" >
                                <h5 className="text-primary"> Edit Details </h5>


                                {input.imgUrl && <img src={input.imgUrl} style={{ maxWidth: '100%' }} alt="..." />}

                                {(progress !== 0) &&
                                    <div className="progress mb-2">
                                        <div className="progress-bar" style={{ width: progress + '%' }}  ></div>
                                    </div>
                                }
                                {(!input.imgUrl) && <div className="form-group" >
                                    <label className="pb-2">Select Image</label>
                                    <input className="form-control text-center" type="file" accept="image/*" capture="camera" onChange={handleChangeImg} />

                                    {(file.name) && <button className="btn btn-success mt-4 w-100 text-center" onClick={handleUpload} >upload</button>}


                                </div>
                                }

                                {(input.imgUrl) && <button className="btn-danger btn text-center mt-2" onClick={() => { handleRemoveImg(input.imgUrl) }} >Remove Image</button>}

                                {(input.imgUrl) &&
                                    <>
                                        <div className="form-group" >
                                            <label className="pb-2"> Order Number</label>
                                            <input className="form-control text-center" value={input.order} onChange={handleChange} name="order" />
                                        </div>
                                        <div className="form-group" >
                                            <label className="pb-2"> Name</label>
                                            <input className="form-control text-center" value={input.name} onChange={handleChange} name="name" />
                                        </div>
                                        <div className="form-group" >
                                            <label className="pb-2"> Staff type</label>
                                            <select className="form-control text-center" value={input.type} onChange={handleChange} name="type" >
                                                <option value="medical">medical</option>
                                                <option value="administration" selected>administration</option>
                                            </select>
                                        </div>
                                        <div className="form-group" >
                                            <label className="pb-2"> Title</label>
                                            <input className="form-control text-center" value={input.title} onChange={handleChange} name="title" />
                                        </div>
                                        <div className="form-group" >
                                            <label className="pb-2"> Doctor's Quaification</label>
                                            <input className="form-control text-center" value={input.qualification} onChange={handleChange} name="qualification" />
                                        </div>
                                        <div className="form-group" >
                                            <label className="pb-2"> Doctor's Description</label>
                                            <textarea className="form-control text-center" value={input.description} onChange={handleChange} name="description" />
                                        </div>





                                        <div className="form-group" >
                                            <center>
                                                <button disabled={(loading) ? 'disabled' : ''} className="btn btn-success mt-4 w-100 text-center" onClick={() => { saveChanges() }}>{(loading) ? 'Saving...' : 'Save Changes'}</button>
                                                <button className="btn btn-secondary mt-4 w-100 text-center" onClick={() => handleCancel()}>Cancel Editing</button>

                                            </center>
                                        </div>


                                    </>}
                            </div>

                            }




                            <div className="col-lg-9" >
                                {error && <>   <div className="img-thumbnail bg-secondary p-1 mb-2 text-white" >{error} <button className="text-danger" onClick={() => setError('')}> x</button></div></>}
                                <div className="row" >



                                    {
                                        fetchedData.map(item => {
                                            let data = item.data();
                                            return (
                                                <div className="col-lg-3 " >
                                                    <div className="card p-2 doctor-card" >
                                                        <img src={(data.imgUrl) ? data.imgUrl : 'https://via.placeholder.com/150'} className="card-img-top mx-auto" alt="..." style={{ borderRadius: '50%' }} />
                                                        <div className="card-body">
                                                            <h5>{data.name}</h5>
                                                            <p className="card-text">{data.title} </p>
                                                            <span> {data.qualification}</span>
                                                            <hr />
                                                            <center>
                                                            <p className=" btn text-danger mt-2 p-0 m-4" > [{data.order}] </p>

                                                                <button className=" btn text-danger mt-2 p-0  m-4" onClick={() => { if (window.confirm('Click ok if are you sure to delete ? ')) handleDelete(item.id, data.imgUrl) }} > <FaTrash /> </button>

                                                                <button className=" btn text-danger mt-2 p-0 m-4" onClick={() => { getDataForUp(item.id, data) }} > <FaEdit /> </button>
                                                            </center>
                                                        </div>

                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DoctorManagement