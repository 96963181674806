import React, { useEffect, useState } from "react";
import imageCompression from 'browser-image-compression';
import DashboardNav from "./DashboardNav";
import SideBar from "./SideBar";
import { storageRef } from "../Firebase";
import { FaTrash } from "react-icons/fa";

const GalleryManagement = () => {
    const [file, setFile] = useState('');
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState('');
   // const [url, setUrl] = useState('');
    const imgRef = storageRef.ref('gallery/' + file.name);

    const [trigger, setTrigger] = useState(true);
    const handleChangeImg = (event) => {
        setFile(event.target.files[0]);
        
    }
    const handleUpload = async (fileTu) => {
        imgRef.put(fileTu).on('state_changed', (snap) => {
            let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
            setProgress(percentage);
        }, (err) => {
            setError(err);
        }, async () => {
          //  const urlim = await imgRef.getDownloadURL();
            // const createdAt = new Date();

            setProgress(0)
           // setUrl(url);
            setTrigger(!trigger)

        });
    }


    async function handleImageUpload() {

        const imageFile =file;
        console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
        console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }
        try {
          const compressedFile = await imageCompression(imageFile, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

          await handleUpload (compressedFile); // write your own logic
        } catch (error) {
          console.log(error);
        }

      }

    
    const handleDelete = async (url)=>{
        console.log(url)
        const file = storageRef.refFromURL(url);
        file.delete().then(function () {
                setTrigger(!trigger)
            // File deleted successfully
            console.log("File Deleted")
        }).catch(function (error) {
            setError(error)
        });

    }


    const [files, setFiles] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {

            let result = await storageRef.ref('gallery').listAll();
            let urlPromises = result.items.map(imageRef => imageRef.getDownloadURL());

            return Promise.all(urlPromises);

        }

        const loadImages = async () => {
            const urls = await fetchImages();
            setFiles(urls);
        }
        loadImages();
    }, [trigger]);


    return (
        <>
            <DashboardNav />
            <div className="container-fluid bg-light" >
                <div className="row dash-row" >
                    <SideBar />
                    <div className="col-lg-10 justify-content-md-center p-4">
                        <div className="row   ">
                            <div className="img-thumbnail col-sm-4 p-2" >
                                <h5 className="text-primary"> Add Photos to Gallery </h5>



                               
                                <div className="form-group" >
                                    <label className="pb-2">Select Image</label>
                                    <input className="form-control text-center" type="file" accept="image/*" capture="camera" onChange={handleChangeImg} />
                                       { (error) && <span className="text-danger"> {error} </span> }

                                    <button className="btn btn-success mt-4 w-100 text-center" onClick={handleImageUpload} >upload</button>
                                </div>
                                {(progress !== 0) &&
                                    <div className="progress mt-2">
                                        <div className="progress-bar" style={{ width: progress + '%' }}  ></div>
                                    </div>
                                }
                            </div>

                            <div className="img-thumbnail col-sm-8 p-2" >
                                    <div className="row" >
                                        <h5 className="text-success">Photos </h5>
                                {files.map((url, idx) => {
                                   
                                        return (
                                            <div className="col-lg-4 pb-2" >
                                                <div className="photo-card">
                                                <img className="w-100 " src={url} />
                                                <span className="text-muted" ><button className="btn text-danger" onClick={() => {handleDelete(url)}} > <FaTrash /></button></span>
                                            </div>
                                            </div>
                                        )
                                })}
                            </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default GalleryManagement;