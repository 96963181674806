import React ,{useEffect , useState} from "react";
import { db } from "../Firebase";

const FooterText =() =>{

    const [trigger, setTrigger] = useState(true);
    // const [header,setHeader] =useState([]);
    const [input, setInput] = useState({ Heading: '', Description: '' });
    const [currentId, setCurrentId] = useState('');
    const [loading, setLoading] = useState(true);
    const dbRef = db.collection('footertext');
    useEffect(() => {
        const getData = async () => {
            const data = await dbRef.limit(1).get();
            //setHeader(data.docs.map((doc)=>({...doc.data(),id:doc.id})));

            data.docs.forEach(doc => {
                setInput(doc.data())
                setCurrentId(doc.id)
                setLoading(false)
            })

        }
        getData();
    }, [trigger])

    const saveChanges = async () => {
        setLoading(true)
        try {
            await dbRef.doc(currentId).update(input)
            console.log("added ss")
            setTrigger(!trigger)
            setLoading(false)
        }
        catch (e) {

        }
    }

    const handleChange = (event) => {
        setInput(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }

    return(
        <div className=" col-sm-3 p-2 img-thumbnail" >
        <h5 className="text-primary"> Manage Footer Text </h5>

            <div className="form-group" >
                <label className="pb-2"> Description </label>
                <textarea className="form-control " value={input.description} onChange={handleChange} name="description" />
            </div>
            <div className="form-group" >
                <label className="pb-2"> Title </label>
                <input className="form-control text-center" value={input.footerCopyRight} onChange={handleChange} name="footerCopyRight" />
            </div>
            <div className="form-group mt-2" >
                                    <center>
                                        <button disabled={(loading) ? 'disabled' : ''} className="btn btn-success mt-4 w-100 text-center" onClick={saveChanges}>{(loading) ? 'Saving...' : 'Save Changes'}</button>
                                    </center>
             </div>
        </div>
    )
}
export default FooterText;