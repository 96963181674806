import React from "react";


const DoctorCard = (props) => {
    return (
        <div className="col-lg-3  p-4" >
        <div className="card p-2 doctor-card" >
            <img src={props.img} className="card-img-top mx-auto" alt="..." style={{borderRadius:'50%' , maxWidth:'250px' }} />
            <div className ="card-body">
            <h5>{props.name}</h5>
            <p className ="card-text">{props.title} </p>
            <span> {props.qualification} </span>
            </div>
        </div>
        </div>
    )
}
export default DoctorCard