import React from "react";
import { useData } from "./DataContext";



const Banner = () => {
    const {home}= useData()
   

    return (
        <>
            <div className="banner container-fluid">
              
                <div className="text-mask" style={{zIndex:'2'} }>
                <img src={home.headerPhoto}   alt="Msc Clinic"/>
                    <h1>{home.headerHeading}</h1>
                
                </div>
            </div>
        </>
    )

}
export default Banner;