import React, { useEffect, useState } from 'react';
import { useAuth } from "../AuthContext";
import { Link, useHistory } from "react-router-dom";
import MscLogo from '../images/logo2.png'




export default function Login() {
    const { login ,currentUser} = useAuth();
    const [input, setInput] = useState({});

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(()=>{
        if(currentUser){
            history.push("/manage")
        }
    },[])
    const handleChange = (event) => {
        setInput(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }
    const handleSubmit = async () => {
        try {
            setError("")
            setLoading(true);
            await login(input.username, input.password);
            history.push("/manage")
        } catch {
            setError("Sorry , Try Again !")
        }

        setLoading(false);
    }
    return (
        <> 
         <div className="container">
            <div className="row justify-content-md-center">
            <center > <img className="login-logo mt-4" src={MscLogo} /> </center>
                <div className="login-wrapper col-sm-4 mt-4 bordered">
                   
                    <h5 className="text-center text-primary font-weight-bold">LOGIN</h5>
                   
                    <div className="form-group">
                        <label>Username:</label>
                        <input className="form-control" type="text" value={input.username} name="username" onChange={handleChange} err="" />
                    </div>
                    <div className="form-group">
                    <label>Passcode:</label>
                        <input className="form-control" type="password"  value={input.password} name="password" onChange={handleChange} err="" />
                    </div>

                    <small className="text-danger" >{error}</small>
                    <div className="form-group">
                        <center><button disabled={loading} className="btn btn-primary p-2 mt-2 w-100" onClick={handleSubmit} >LOGIN</button></center>
                    </div>

                    <div  className="m-2 text-center">Remeber Password ? </div>
                </div>
            </div>
        </div>
        </>

    )
}


