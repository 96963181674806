import React, { useEffect } from "react";
import Footer from "./Footer";
import Gallery from "./Gallery";
import NavBar from "./NavBar";
import { DataProvider } from "./DataContext";

const GalleryMain = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
      },[])
    return (
        <>
        <DataProvider>
            <NavBar />
            <Gallery num="" />
            <Footer />
        </DataProvider>
        </>

    )
}
export default GalleryMain