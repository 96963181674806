import React, { useState } from "react";
import './Dash.css';
import { useAuth } from "../AuthContext";
import { Link, useHistory } from "react-router-dom";


import { FaPowerOff,FaBars } from 'react-icons/fa';
import MscLogo from '../images/logo2.png'
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";

const DashboardNav = () => {
  const {logout} = useAuth();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const history =useHistory();
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
document.title="ManageSite"
  const handleLogout = async()=>{
    const loggedOut = await logout();
    if(logout){
      history.push("/")
    }

  }
  return (
    <>
      
      <div className="msc-nav">
        <div className="container">
          <nav className="navbar navbar-expand-sm">
            <a className="navbar-brand" href=""><img src={MscLogo} className="header-logo"/> Admin </a>
            <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
              <FaBars />
            </button>
            <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <Link className="nav-link active" to="/">Go to Home</Link>
                </li>
               
              </ul>
              <div className="option-item"><i className="search-btn fas fa-search"></i>
                <a href="#" className="btn btn-primary" onClick={handleLogout} >Logout <i><FaPowerOff /></i></a>
              </div>

            </div>

          </nav>
        </div>
      </div>



    </>
  )
}
export default DashboardNav