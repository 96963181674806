import React, { useState, useEffect } from "react";
import { db } from "../Firebase";
import DoctorCard from "./DoctorCard";
import Footer from "./Footer";
import NavBar from "./NavBar";
import Sprator from "./Sprator";
import { DataProvider ,useData} from "./DataContext";


const DoctorsMain = () => {
    
    const [fetchedData, setFetchedData] = useState([]);
    const dbRef = db.collection('/doctors').orderBy("order")


    useEffect(() => {


        window.scrollTo(0, 0);

        const getData = async () => {
            const data = await dbRef.get();

            const items = data.docs;
            setFetchedData(items);

            // setFetchedData(data.docs.map((doc)=>({...doc.data(),id:doc.id})));

            // data.docs.forEach(doc => {
            //     setFetchedData(doc.data())
            //     setCurrentId(doc.id)
            //     setLoading(false)
            // })
            console.log('data ' + fetchedData)
        }
        getData();
    }, [])


    return (
        <>
          <DataProvider>
            <NavBar />
            <section className="doctor-area">
                <div className="container mt-4">
                    <div className="row m-0 justify-content-center">
                        <div className="section-title text-center">
                            <h2>Meet Qur qualified Professionals</h2>
                            {/* <h2>{home.doctorHeading}</h2>
                            <p>{home.doctorDes}</p> */}
                        </div>

                        {
                            fetchedData.map(item => {
                                let data = item.data();
                                if(data.type==="medical")
                                return (<>
                                    <div className="row mt-4 img-thumbnail mb-4 doctor-head">
                                        <div className="col-lg-4"><img className="w-100" src={data.imgUrl} alt="..." style={{ borderRadius: '2px' }} /></div>
                                        <div className="col-lg-8 pt-2">
                                            <center>
                                                <h5>{data.name}</h5>
                                                <p>{data.title} </p>
                                                <span>{data.qualification}</span>
                                                <p className="mt-4 text-dark editorS">{data.description}</p>

                                            </center>
                                        </div>
                                    </div>


                                </>
                                )
                            })}

                    </div>
                </div>
            </section>
          
            <Footer />
            </DataProvider>
        </>

    )
}
export default DoctorsMain;