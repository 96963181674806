import React from "react";

const Sprator = () =>{
    return(
        
        <div className="container-fluid sprator bg-dark mh-100" >
           
        </div>

    )
}
export default Sprator;