import React, { useState, useEffect } from "react";
import DashboardNav from "./DashboardNav";
import { storageRef, db } from "../Firebase";
import ReactQuill from 'react-quill';
import SideBar from "./SideBar";
import { FaEdit, FaPhotoVideo, FaTrash } from "react-icons/fa";
import Sprator from "../components/Sprator";
import { Link } from "react-router-dom";


const TreatmentManagement = () => {
    const [file, setFile] = useState('');
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState('');
    const [url, setUrl] = useState('');
    const imgRef = storageRef.ref('Images/' + file.name + '_' + new Date());
    const [fetchedData, setFetchedData] = useState([]);
    const [trigger, setTrigger] = useState(true);
    // const [header,setHeader] =useState([]);
    const [input, setInput] = useState({});
    const [currentId, setCurrentId] = useState('');
    const [loading, setLoading] = useState(false);
    const dbRef = db.collection('/treatments');
    const handleChangeImg = (event) => {
        setFile(event.target.files[0]);
        console.log(file);
    }
    const handleUpload = async () => {
        imgRef.put(file).on('state_changed', (snap) => {
            let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
            setProgress(percentage);
        }, (err) => {
            setError(err);
        }, async () => {
            const urlim = await imgRef.getDownloadURL();
            // const createdAt = new Date();

            setProgress(0)
            setUrl(urlim);
            setInput(prev => ({ ...prev, imgUrl: urlim }));

        });
    }


    useEffect(() => {


        const getData = async () => {
            const data = await dbRef.get();

            const items = data.docs;
            setFetchedData(items);

            // setFetchedData(data.docs.map((doc)=>({...doc.data(),id:doc.id})));

            // data.docs.forEach(doc => {
            //     setFetchedData(doc.data())
            //     setCurrentId(doc.id)
            //     setLoading(false)
            // })
            console.log('data ' + fetchedData)
        }
        getData();
    }, [trigger])

    const saveChanges = async () => {
        setLoading(true)
        try {
            await dbRef.doc(currentId).update(input)
            console.log("added ss")
            setTrigger(!trigger)
            setLoading(false)
        }
        catch (e) {

        }
    }

    const postData = async () => {
        setLoading(true)

        const err = await dbRef.add(input);
        if (!err) {
            console.log(err);
        }
        else {
            setTrigger(!trigger);
            setInput({});
            setLoading(false)
            setUrl('')
        }

    }
    const handleDelete = async (id, url) => {

        const file = storageRef.refFromURL(url);
        
        try{
            const deletedImg = await file.delete();
        const deletedData = db.collection('treatments').doc(id).delete();
        await Promise.all([deletedImg, deletedData]);
            setTrigger(!trigger)
        }
        catch(e){
            setError(e.code);
        }
    

    }

    const handleCancelImg = () => {
        setUrl('');
        setInput({});
    }


    const handleChange = (event) => {
        setInput(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }
    const handleChangeEdi = (event) => {
        setInput(prev => ({ ...prev, description:ReactQuill.root.innerHTML }))
    }

    return (
        <>
            <DashboardNav />
            <div className="container-fluid bg-light" >
                <div className="row dash-row" >
                    <SideBar />
                    <div className="col-lg-10 justify-content-md-center p-4">
                        <div className="row   ">
                            <div className="img-thumbnail col-lg-3 p-2" >
                                <h5 className="text-primary"> Manage Treatments </h5>


                                {
                                    (url) && <img src={url} style={{ maxWidth: '100%' }} />
                                }
                                {(progress !== 0) &&
                                    <div className="progress mb-2">
                                        <div className="progress-bar" style={{ width: progress + '%' }}  ></div>
                                    </div>
                                }
                                {(!url) && <div className="form-group" >
                                    <label className="pb-2">Select Image</label>
                                    <input className="form-control text-center" type="file" accept="image/*" capture="camera" onChange={handleChangeImg} />

                                    <button className="btn btn-success mt-4 w-100 text-center" onClick={handleUpload} >upload</button>

                                </div>
                                }
                                {(url) && <button className="btn-danger btn text-center mt-2" onClick={handleCancelImg} >Cancel</button>}

                                {(url) && (<>
                                    <div className="form-group" >
                                        <label className="pb-2"> Treatment title</label>
                                        <input className="form-control text-center" value={input.title} onChange={handleChange} name="title" />
                                    </div>
                                    
                                    <div className="form-group" >
                                        <label className="pb-2"> Brief Description</label>
                                        <textarea className="form-control text-center" value={input.description} onChange={handleChange} name="description" />
                                       
                                    </div>





                                    <div className="form-group" >
                                        <center>
                                            <button disabled={(loading) ? 'disabled' : ''} className="btn btn-success mt-4 w-100 text-center" onClick={() => { postData() }}>{(loading) ? 'Saving...' : 'Add Treatment'}</button>
                                        </center>
                                    </div>
                                </>)}
                            </div>



                            <div className="col-lg-9" >
                               {error && <p className="text-danger text-center h5 img-thumbnail">Error : {error}  <button className=" btn text-danger p-1 btn-border " onClick={() => { setError('') }} > x</button> </p> }
                                <div className="row" >

                                    {
                                        fetchedData.map(item => {
                                            let data = item.data();
                                            return (


                                                <div className="col-lg-3 p-2" >
                                                    <div className="img-thumbnail p-2" >
                                                        <img src={data.imgUrl} className="card-img-top mx-auto" alt="..."  />
                                                        <h5>{data.title}</h5>
                                                        <Sprator />
                                                        <span> {data.description.substring(0,100)} </span>
                                                        <center> <button className=" btn text-danger mt-2 p-0  m-4" onClick={() => { if (window.confirm('Click ok if are you sure to delete ? ')) handleDelete(item.id,data.imgUrl) }} > <FaTrash /> </button>

                                                        <button className=" btn text-danger mt-2 p-0 m-4" onClick={() => { }} > <FaEdit /> </button>
                                                        <Link to={"/treatment-photo/"+item.id} className="btn text-danger mt-2 p-0 m-4"><FaPhotoVideo /> </Link> </center>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TreatmentManagement;