import React, {useEffect,useState} from "react";

import { storageRef } from "../Firebase";
import { Link } from "react-router-dom";
import { FaCamera } from "react-icons/fa";

import { useData } from "./DataContext";



const Gallery = (props) =>{
    const {home}=useData();
    //const images = [{ source: img1 }, { source: img2 }]
   const [files,setFiles]=useState([]);
   const [modalUrl, setModalUrl]=useState('');
  

   
const Modal = (props) => {

    return (
        <>
           
            <div className="modal show fade" id="myModal" style={{display:'block' , backgroundColor:'rgba(0,0,0,0.8)'}} >
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">


                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={()=>setModalUrl('')}></button>
                        </div>


                        <div className="modal-body p-0">
                     
                            <img src={props.url}  className="w-100" alt="..."/>
                  
                        </div>


                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={()=>{setCount(count-1)}} >prev </button>
                            <button type="button" className="btn btn-danger" onClick={()=>{setCount(count+1)}} >next </button>
                        </div>  */}
                    </div>
                </div>
            </div>
        </>
    )
}
    useEffect(() => {
        const fetchImages = async () => {
    
        let result = await storageRef.ref('gallery').listAll()
            let urlPromises = result.items.map(imageRef => imageRef.getDownloadURL());
        
            return Promise.all(urlPromises);
    
        }
        
        const loadImages = async () => {
            const urls = await fetchImages();
            setFiles(urls);
        }
        loadImages();
        }, []);
    console.log(files)
         
   
    return(
        <>
         <section className="doctor-area">
                <div className="container mt-4">
                <div className="row m-0 justify-content-center">
                <div className="section-title text-center">
                        <span>Photo Gallery</span>
                        <h2>{home.galleryHeading}</h2>
                        <p>{home.galleryDes}</p>
                    </div>
               
                {files.map((url,idx)=>{
                    if(props.num){
                        if(idx<props.num)
                        return(
                            <div className="col-lg-4">
                                <img className="w-100 mb-4 photo-card" src={url} onClick={()=>setModalUrl(url)}/>
                                
                            </div>
                        )
                    }
                    else{
                        return(
                            <div className="col-lg-4">
                                <img className="w-100 mb-4 photo-card" src={url} onClick={()=>setModalUrl(url)}/>
                                
                            </div>
                        )
                    }
                   
                })}
              
              {
                  (modalUrl) && <Modal url={modalUrl} />
              }
             

                </div>
                <center>
                { (props.num) && <Link className="mb-4 btn btn-primary photo-link pl-4 " to="/photo-gallery" > See More Pictures <i><FaCamera /></i></Link>
                
            }
            </center>
                </div>
            </section>
            <p className="p-4 m-4"></p>
        </>
    )
}

export default Gallery;