import React, { useEffect } from "react";
import Footer from "./Footer";
import Mission from "./Mission";
import NavBar from "./NavBar";
import { DataProvider } from "./DataContext";

const TreatmentsMain=()=>{
    useEffect(()=>{
        window.scrollTo(0, 0);
      },[])
return(
    <>
    <DataProvider>
    <NavBar />
    <Mission />

    <Footer />
    </DataProvider>
    </>
)
}
export default TreatmentsMain;