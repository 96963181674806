import React from "react";
import { Link } from "react-router-dom";

const SideBar=()=>{
    return(
        <div className="col-lg-2 bg-dark text-center sidebar p-0">
            <div className="btn-group-vertical" >
            <Link className="text-white btn" to="/manage" >Website Config</Link> 
            <Link className="text-white btn" to="/doctor-management" >Doctors</Link> 
            <Link className="text-white btn" to="/gallery-management" >Gallery</Link> 
            <Link className="text-white btn" to="/treatment-management" >Treatments</Link> 
            <Link className="text-white btn" to="/footer-management" >Footer</Link>
            </div>
        </div>
    )
}
export default SideBar;