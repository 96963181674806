import React, { useContext, useState, useEffect } from "react"
import {db} from "../Firebase"

const DataContext = React.createContext()

export function useData() {
  return useContext(DataContext)
}

export function DataProvider({ children }) {
  
  const [loading, setLoading] = useState(true)
  const [footerText , setFooterText] =useState({});
  const [footerAddress , setFooterAddress] =useState([]);
  const [footerContact , setFooterContact] =useState([]);
  const [footerEmail , setFooterEmail] =useState([]);
  const [home , setHome] =useState([]);

  const footerTextDb= db.collection('footertext');
  const footerAddressDb= db.collection('address');
  const footerContactDb= db.collection('contacts');
  const footerEmailDb= db.collection('emails');
  const homePageDb= db.collection('home');


  useEffect(() => {
    
    const getFooterText= async ()=>{
        const data = await footerTextDb.limit(1).get();
        //setHeader(data.docs.map((doc)=>({...doc.data(),id:doc.id})));

        data.docs.forEach(doc => {
            setFooterText(doc.data())
            //setLoading(false)
        })
    }


    const getHomePage= async ()=>{
      const data = await homePageDb.limit(1).get();
      //setHeader(data.docs.map((doc)=>({...doc.data(),id:doc.id})));

      data.docs.forEach(doc => {
          setHome(doc.data())
          //setLoading(false)
      })
  }

    const getAddress = async () => {
        
        const dataAddress = await footerAddressDb.get();
        const itemsAddress = dataAddress.docs;
        setFooterAddress(itemsAddress);

    }

    const getContact = async() =>{
        const contact = await footerContactDb.get();
        const items=contact.docs;
        setFooterContact(items);
        
    }

    const getEmail=async()=>{
        const email= await footerEmailDb.get();
        const items =email.docs;
        setFooterEmail(items)

    }
   
    getHomePage();

    getAddress();

    getFooterText();

    getContact()

    getEmail();
   
    setLoading(false)
    

   
    
  }, [])
 
 

  const value = {
   footerText,
   footerAddress,
   footerContact,
   footerEmail,
   home

  }

  return (

    <DataContext.Provider value={value}>
      {!loading && children  }
    </DataContext.Provider>
  )
}
