import React, { useState, useEffect } from "react";
import DashboardNav from "./DashboardNav";
import { db, storageRef } from "../Firebase";
import SideBar from "./SideBar";
import Sprator from "../components/Sprator";

const Dashboard = () => {
    const [file, setFile] = useState('');
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState('');
    const [trigger, setTrigger] = useState(true);

    const [url, setUrl] = useState('');
    const [tempUrl, setTempUrl] = useState('');
   
    const [input, setInput] = useState({ Heading: '', Description: '' });
    const [currentId, setCurrentId] = useState('');
    const [loading, setLoading] = useState(true);
    const dbRef = db.collection('/home');
    const imgRef = storageRef.ref('Images/' + file.name + '_' + new Date());


    const handleChangeImg = (event) => {
        setFile(event.target.files[0]);

    }

    const handleUpload = async () => {
        imgRef.put(file).on('state_changed', (snap) => {
            let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
            setProgress(percentage);
        }, (err) => {
            setError(err);
        }, async () => {
            const urlim = await imgRef.getDownloadURL();
           

            setProgress(0)
            setUrl(urlim);

            setInput(prev => ({ ...prev, headerPhoto: urlim }));
            setFile('')

        });
    }


    useEffect(() => {
        const getData = async () => {
            const data = await dbRef.limit(1).get();
         

            data.docs.forEach(doc => {
                setInput(doc.data())
                setCurrentId(doc.id)
                setLoading(false)
            })
            setTempUrl(input.headerPhoto)



        }
        getData();
    }, [trigger])

    const saveChanges = async () => {
        setLoading(true)
        try {
            await dbRef.doc(currentId).update(input)
            setTrigger(!trigger)
            setLoading(false)

        }
        catch (e) {

        }
    }
    






    const handleChange = (event) => {
        setInput(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }
    return (
        <>
            <DashboardNav />
            <div className="container-fluid bg-light" >
                <div className="row dash-row" >
                    <SideBar />
                    <div className="col-lg-10 justify-content-md-center p-4">
                        <div className="row   ">
                            <div className="img-thumbnail col-sm-8 p-2 bg-light" >
                                <h5 className="text-primary"> Manage Header Section </h5>
                                <Sprator />

                                <div className="form-group pb-4 mb-4" >
                                    <label className="pb-2 text-danger h5">Address (Below the logo)</label>
                                    <textarea className="form-control text-center"  value={input.headerAddress} onChange={handleChange} name="headerAddress" />  
                                </div>

                                <div className="form-group pb-4 mb-4" >
                                    <label className="pb-2 text-danger h5">Header Heading (Below the logo)</label>
                                    <textarea className="form-control text-center"  value={input.headerDes} onChange={handleChange} name="headerDes" />  
                                </div>
                                <label className="pb-2 text-danger h5">Header Photo</label>
                                <img src={input.headerPhoto} className="w-100" alt="..." />
                                <div className="form-group" >
                                    <label className="pb-2 pt-4 text-danger">Select Image</label>
                                    <input className="form-control text-center" type="file" accept="image/*" capture="camera" onChange={handleChangeImg} />
                                    {(error) && <span className="text-danger"> {error} </span>}
                                    {(progress !== 0) &&
                                        <div className="progress mb-2">
                                            <div className="progress-bar" style={{ width: progress + '%' }}  ></div>
                                        </div>
                                    }




                                    {(file) && <button className="btn btn-success mt-4 mb-4 text-center" onClick={handleUpload} >upload</button>}






                                </div>
                                <div className="form-group" >
                                    <label className="pb-2 text-danger h5"> Heading (Over The Header Image)</label>
                                    <input className="form-control text-center" value={input.headerHeading} onChange={handleChange} name="headerHeading" />
                                </div>
                              
                               
                                <div className="row mt-4">
                                <label className=" text-danger h5">Phone Numbers</label>
                                    <div className="col-lg-6 form-group">
                                        <label className=""> Primary Phone Number</label>
                                        <input className="form-control text-center  mt-2" value={input.headerPhone} onChange={handleChange} name="headerPhone" />
                                    </div>

                                    <div className="col-lg-6 form-group">
                                        <label className=""> Fax Number</label>
                                        <input className="form-control text-center  mt-2" value={input.headerPhoneTwo} onChange={handleChange} name="headerPhoneTwo" />
                                    </div>
                                    </div>
                                <p className="mt-4 pt-4 mb-4 pb-4"> </p>

                                <h5 className="text-primary mt-4"> Manage Clinic General Information Section </h5>
                                <Sprator />
                                <div className="form-group mt-4" >
                                    <label className="pb-2 text-danger h5"> Clinic Heading</label>
                                    <input className="form-control text-center" value={input.featureHeading} onChange={handleChange} name="featureHeading" />
                                </div>
                                <div className="form-group  text-danger h5" >
                                    <label className="pb-2"> Clinic Description</label>
                                    <textarea className="form-control text-center" rows="20" value={input.featureDes} onChange={handleChange} name="featureDes" />
                                </div>

                               


                                <p className="mt-4 pt-4 mb-4 pb-4"> </p>

                               

                                <h5 className="text-primary mt-4 "> Manage Treatment Section </h5>
                                <Sprator />
                                <div className="form-group mt-4" >
                                    <label className="pb-2 text-danger h5"> Heading</label>
                                    <input className="form-control text-center" value={input.treatmentHeading} onChange={handleChange} name="treatmentHeading" />
                                </div>
                                <div className="form-group  text-danger h5" >
                                    <label className="pb-2"> Description</label>
                                    <textarea className="form-control text-center" value={input.treatmentDes} onChange={handleChange} name="treatmentDes" rows="4" />
                                </div>
                                <p className="mt-4 pt-4 mb-4 pb-4"> </p>

                                <h5 className="text-primary mt-4 "> Manage Doctor's Section </h5>
                                <Sprator />
                                <div className="form-group mt-4" >
                                    <label className="pb-2 text-danger h5"> Heading</label>
                                    <input className="form-control text-center" value={input.doctorHeading} onChange={handleChange} name="doctorHeading" />
                                </div>
                                <div className="form-group  text-danger h5" >
                                    <label className="pb-2"> Description</label>
                                    <textarea className="form-control text-center" value={input.doctorDes} onChange={handleChange} name="doctorDes" rows="4" />
                                </div>


                                <p className="mt-4 pt-4 mb-4 pb-4"> </p>

                                <h5 className="text-primary mt-4 "> Manage Photo Gallery Section </h5>
                                <Sprator />
                                <div className="form-group mt-4" >
                                    <label className="pb-2 text-danger h5"> Heading</label>
                                    <input className="form-control text-center" value={input.galleryHeading} onChange={handleChange} name="galleryHeading" />
                                </div>
                                <div className="form-group  text-danger h5" >
                                    <label className="pb-2"> Description</label>
                                    <textarea className="form-control text-center" value={input.galleryDes} onChange={handleChange} name="galleryDes" rows="4" />
                                </div>
                                <p className="mt-4 pt-4 mb-4 pb-4"> </p>

                                <div className="form-group" >
                                    <center>
                                        <button disabled={(loading) ? 'disabled' : ''} className="btn btn-success mt-4 w-100 text-center" onClick={saveChanges}>{(loading) ? 'Saving...' : 'Save Changes'}</button>
                                    </center>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Dashboard;