import React, { useState, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { db } from "../Firebase";
import { useData } from "./DataContext";
import DoctorCard from "./DoctorCard";


const Doctors = () => {
    const {home}=useData();

    const [fetchedData, setFetchedData] = useState([]);
    const dbRef = db.collection('/doctors');


    useEffect(() => {


        const getData = async () => {
            const data = await dbRef.orderBy("order").get();

            const items = data.docs;
            setFetchedData(items);

            // setFetchedData(data.docs.map((doc)=>({...doc.data(),id:doc.id})));

            // data.docs.forEach(doc => {
            //     setFetchedData(doc.data())
            //     setCurrentId(doc.id)
            //     setLoading(false)
            // })
            console.log('data ' + fetchedData)
        }
        getData();
    }, [])


    return (
        <>
            <section className="doctor-area">
                <div className="container mt-4">
                    <div className="row m-0 justify-content-center">
                        <div className="section-title text-center">
                            <span>Doctors</span>
                            <h2>{home.doctorHeading}</h2>
                            <p>{home.doctorDes}</p>
                        </div>

                           {
                            fetchedData.map(item => {
                                let data = item.data();
                                if(data.type==="medical")
                                return (

                                    <DoctorCard img={ data.imgUrl} name={ data.name} title={data.title } qualification={data.qualification } />
                                )
                            })}

                        <hr/>
                        <h4 className="text-center uppercase" style={{fontFamily: "'Bebas Neue', cursive"}}>Clinic Administration</h4>
                        <hr/>
                      {
                            fetchedData.map(item => {
                                let data = item.data();
                                if(data.type!=="medical")
                                return (

                                    <DoctorCard img={ data.imgUrl} name={ data.name} title={data.title } qualification={data.qualification } />
                                )
                            })}

                    </div>
                    <center><Link className="mb-4 btn btn-primary photo-link pl-4 " to="/doctors" > Know More ...<i><FaArrowRight /></i></Link> </center>
                </div>
            </section>
        </>

    )
}
export default Doctors;