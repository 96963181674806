import React from "react";
import { FaHandHoldingHeart, FaHandshake, FaHandHoldingUsd, FaHandHoldingWater, FaPrescription, FaDoorClosed } from 'react-icons/fa';
import { useData } from "./DataContext";
import Sprator from "./Sprator";


const Services = () => {
    const { home } = useData()
    return (
        <>
            <section className="main-services-area pt-100">
                <div className="container">
                <div className="section-title text-center">
            {/* <span>Main Features</span> */}
                <h2>{home.featureHeading}</h2>
                <Sprator />
                </div>

                <div className="row justify-content-center">
                <div className="col-lg-10">
                <p className="editorS w-100 text-center text-about">{home.featureDes}</p>
                </div>
                </div>

                <p className="pt-4 mt-4 mb-4"></p>


                </div>


            </section>
        </>
    )
}
export default Services;