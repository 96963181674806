import React, { useState, useEffect } from "react";
import DashboardNav from "./DashboardNav";
import { db } from "../Firebase";
import SideBar from "./SideBar";
//import { FaArrowRight, FaPowerOff } from "react-icons/fa";
import DoctorCard from '../components/DoctorCard';
import FooterText from "./FooterText";
import Address from "./Address";
import Contacts from "./Contacts";
import Email from "./Email";

const ManageFooter = () => {
    const [trigger, setTrigger] = useState(true);
    // const [header,setHeader] =useState([]);
    const [input, setInput] = useState({ Heading: '', Description: '' });
    const [currentId, setCurrentId] = useState('');
    const [loading, setLoading] = useState(true);
    const dbRef = db.collection('/header');
    useEffect(() => {
        const getData = async () => {
            const data = await dbRef.limit(1).get();
            // setHeader(data.docs.map((doc)=>({...doc.data(),id:doc.id})));

            data.docs.forEach(doc => {
                setInput(doc.data())
                setCurrentId(doc.id)
                setLoading(false)
            })

        }
        getData();
    }, [trigger])

    const saveChanges = async () => {
        setLoading(true)
        try {
            await dbRef.doc(currentId).update(input)
            console.log("added ss")
            setTrigger(!trigger)
            setLoading(false)
        }
        catch (e) {

        }
    }

    const handleChange = (event) => {
        setInput(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }
    return (
        <>
            <DashboardNav />
            <div className="container-fluid bg-light" >
                <div className="row dash-row" >
                    <SideBar />
                    <div className="col-lg-10 justify-content-md-center p-4">
                        <div className="row  img-thumbnail ">
                        <h4 className="text-primary text-center">  Manage Footer  </h4>
                        <hr />
                           <FooterText />
                            <Address />
                            <Contacts />
                            <Email />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ManageFooter;