import React, { useEffect, useState } from "react";
import { FaLocationArrow, FaMailBulk, FaPhone } from "react-icons/fa";
import Sprator from "../components/Sprator";
import { db } from "../Firebase";

const Email = () => {

    const [trigger, setTrigger] = useState(true);
    const [fetchedData, setFetchedData] = useState([]);
    const [input, setInput] = useState({});
    const [currentId, setCurrentId] = useState('');
    const [loading, setLoading] = useState(true);
    const dbRef = db.collection('emails');

    useEffect(() => {

        const getData = async () => {
            const data = await dbRef.get();
            const items = data.docs;
            setFetchedData(items);
            setLoading(false)

        }
        getData();
        console.log("data ddd" + fetchedData)
    }, [trigger])

    const saveChanges = async () => {
        setLoading(true)
        try {
            await dbRef.doc(currentId).update(input)
            console.log("added ss")
            setTrigger(!trigger)
            setLoading(false)
        }
        catch (e) {

        }
    }

    const handleChange = (event) => {
        setInput(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }
    const postData = async () => {
        setLoading(true)

        const err = await dbRef.add(input);
        if (!err) {
            console.log(err);
        }
        else {
            setTrigger(!trigger);
            setInput({email:''});
            setLoading(false)
        }
    }

    return (
        <div className=" col-sm-3 p-2 img-thumbnail" >
            <h5 className="text-primary"> Manage Emails</h5>

            <div className="form-group" >
                <label className="pb-2">Email</label>
                <input className="form-control " value={input.email} onChange={handleChange} name="email" />
            </div>
           
            <div className="form-group mt-2" >
                <center>
                    <button disabled={(loading) ? 'disabled' : ''} className="btn btn-success mt-4 w-100 text-center" onClick={postData}>{(loading) ? 'Saving...' : 'Add Email'}</button>
                </center>
            </div>

            <h5 className="text-primary mt-4"> Contacts</h5>
            <hr />
            <ul className="p-0">
                {
                    fetchedData.map(item => {
                        let data = item.data();
                        return (
                            <>
                             <div className="img-thumbnail mt-2" >
                                 <h5> <FaMailBulk /> {data.email}</h5>
                                

                                 </div>
                            </>

                        )
                })}
            </ul>
        </div>
    )
}

export default Email;