import React from "react";
import { FaLocationArrow, FaMailBulk, FaMailchimp, FaPhone } from "react-icons/fa";
import MscLogo from '../images/logo2.png'
import Sprator from "./Sprator";
import { useData } from "./DataContext";
const Footer = () => {
    const { footerText, footerAddress, footerContact ,footerEmail,home} = useData()
    return (
        <section className="footer-area pt-4">
            <div className="container">
            <div className="row mb-4 pb-4 "> <h5 className="bg-light text-dark text-center editorS"> {home.headerDes} </h5></div>

                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="logo pt-4">
                                <center > <img className="login-logo mt-4" src={MscLogo} /> </center>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget ">
                            <h3 className="text-center"> About Us </h3>
                            <div className="logo text-center">

                                <p>{footerText.description} </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3 >Location</h3>
                            <ul className="departments-list">

                                {
                                    footerAddress.map(item => {
                                        let data = item.data();
                                        return (
                                            <li className="mb-4"><a href={data.link} target="_blank"> <FaLocationArrow /> <strong>{data.title} </strong> <Sprator />  {data.lineOne} <br /> {data.lineTwo} </a></li>

                                        )
                                    })
                                }


                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Contact</h3>
                            <ul className="links-list">

                              

                                {
                                    footerContact.map(item => {
                                        let data = item.data();
                                        return (
                                            <li className="mb-4"><a href={"tel:"+data.phone}><i><FaPhone /> </i> <strong > {data.phone}</strong></a></li>
                                        )
                                    })
                                }

{
                                    footerEmail.map(item => {
                                        let data = item.data();
                                        return (
                                            <li className="mb-4"><a href={"mailto:"+data.email}><i><FaMailBulk /> </i> <strong > {data.email}</strong></a></li>
                                        )
                                    })
                                }


                            </ul>
                        </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Opening Hours</h3>
                            <ul className="opening-hours">
                                <li>Mon - Tues <span>9.00AM - 17.00PM</span></li>
                                <li>Wednesday <span>9.00AM - 17.00PM</span></li>
                                <li>Thursday <span>9.00AM - 17.00PM</span></li>
                                <li>Friday <span>9.00AM - 17.00PM</span></li>
                                <li>Saturday <span>Closed</span></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
                <div className="copyright-area">
                    <p>Copyright <i className="far fa-copyright"></i> {footerText.footerCopyRight}</p>
                </div>
            </div>
        </section>
    )

}
export default Footer;