import React, { useState } from "react";
import { FaBars, FaClinicMedical, FaHome, FaPhone, FaPhotoVideo, FaUserMd } from 'react-icons/fa';
import { NavLink } from "react-router-dom";
import MscLogo from '../images/logo2.png'
import { useData } from "./DataContext";

const NavBar = () => {
  const { home } = useData();

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  return (
    <>


      <div>
        <center>
          <img src={MscLogo} className="header-logo mt-4 mb-2" />
          <h5 className="editorS text-dark">{home.headerAddress}</h5>
          <p className="editorS pt-2 pb-2">{home.headerDes}</p>
          <div className="option-item mb-4"><i class="search-btn fas fa-search"></i>
            <a href={"tel:" + home.headerPhone} className="p-1 m-1 btn-danger"> Call : {home.headerPhone} </a>
            <a className="p-1 m-1 btn-danger"> Fax :{home.headerPhoneTwo} </a>
          </div>
          </center>
          </div>
      <div className="msc-nav sticky-top">
        <div className="container">
          <nav className="navbar navbar-expand-sm ">

            <button class="custom-toggler navbar-toggler mx-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
              Links <FaBars />
            </button>
            <div class={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <NavLink exact className="nav-link" activeClassName="text-primary" to="/"><FaHome className="mb-1" /> Home</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" activeClassName="active" to="/treatments"> <FaClinicMedical className="mb-1" /> Treatments</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" activeClassName="active" to="/doctors"><FaUserMd className="mb-1" /> Doctors</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" activeClassName="active" to="/photo-gallery"><FaPhotoVideo className="mb-1" /> Gallery</NavLink>
                </li>




              </ul>


            </div>

          </nav>
        </div>

      </div>

    </>
  )
}

export default NavBar;