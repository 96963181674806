import React, { useEffect, useState } from "react";
import DashboardNav from "./DashboardNav";
import SideBar from "./SideBar";
import {db, storageRef } from "../Firebase";
import { FaChalkboardTeacher, FaTrash } from "react-icons/fa";
import { useParams } from "react-router";

const TreatmentGallery = () => {
    const { id } = useParams();
    const [file, setFile] = useState('');
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState('');
    const [url, setUrl] = useState({});
    const [data,setData]=useState({t_id:id , url:''})
   const [fetchedData, setFetchedData] = useState([]);

    const imgRef = storageRef.ref('treatment-gallery/' + file.name+new Date() );
    const dbRef = db.collection('/treatments/'+id+'/imgs');
    const [trigger, setTrigger] = useState(true);


    const handleChangeImg = (event) => {
        setFile(event.target.files[0]);
        
    }
    const handleUpload = async () => {
        imgRef.put(file).on('state_changed', (snap) => {
            let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
            setProgress(percentage);
        }, (err) => {
            setError(err);
        }, async () => {
          const urlim = await imgRef.getDownloadURL();
           
            setData(prev => ({ ...prev, url:urlim }));
            setProgress(0)
           // setUrl(url);
            setTrigger(!trigger)

        });
    }
const postData=async()=>{
    try{
        await dbRef.add(data);
        setTrigger(!trigger);
        setData(prev => ({ ...prev, url:'' }));


    }
    catch(e){

    }

}
    
    const handleDelete = async (url,id)=>{
        console.log(url)
        try{
        const file = storageRef.refFromURL(url);
        const imgDeleted = await  file.delete();
        const datDeleted = await dbRef.doc(id).delete();
        Promise.all([imgDeleted,datDeleted]);
            setTrigger(!trigger)
        }catch(e){

        }

    }



    useEffect(() => {


        const getData = async () => {
            const data = await dbRef.get()
          //  .where("t_id", "==", props.id).
            const items = data.docs;
            setFetchedData(items);

            // setFetchedData(data.docs.map((doc)=>({...doc.data(),id:doc.id})));

            // data.docs.forEach(doc => {
            //     setFetchedData(doc.data())
            //     setCurrentId(doc.id)
            //     setLoading(false)
            // })
            console.log('data ' + fetchedData)
        }
        getData();

       
    }, [trigger]);


    return (
        <>
            <DashboardNav />
            <div className="container-fluid bg-light" >
                <div className="row dash-row" >
                    <SideBar />
                    <div className="col-lg-10 justify-content-md-center p-4">
                        <div className="row   ">
                            <div className="img-thumbnail col-sm-4 p-2" >
                                <h5 className="text-primary"> Add Photos to Gallery </h5>



                               
                                <div className="form-group" >
                                { (data.url)&&  <img className="w-100" src={data.url} alt="" />  }
                                    <label className="pb-2">Select Image</label>
                                    <input className="form-control text-center" type="file" accept="image/*" capture="camera" onChange={handleChangeImg} />
                                       { (error) && <span className="text-danger"> {error} </span> }

                                  { (!data.url)&& <button className="btn btn-success mt-4 w-100 text-center" onClick={handleUpload} >upload</button>}
                                  { (data.url)&&   <button className="btn btn-danger mt-4 w-100 text-center" onClick={postData} >add photo</button>}
                                </div>
                                {(progress !== 0) &&
                                    <div className="progress mt-2">
                                        <div className="progress-bar" style={{ width: progress + '%' }}  ></div>
                                    </div>
                                }
                            </div>

                            <div className="img-thumbnail col-sm-8 p-2" >
                                    <div className="row" >
                                        <h5 className="text-success">Photos </h5>
                                        {
                                        fetchedData.map(item => {
                                            let data = item.data();
                                            return (
                                            <div className="col-lg-4 pb-2" >
                                                <div className="photo-card">
                                                <img className="w-100 " src={data.url}  alt="..."/>
                                                <span className="text-muted" ><button className="btn text-danger" onClick={() => {handleDelete(data.url,item.id)}} > <FaTrash /></button></span>
                                            </div>
                                            </div>
                                        )
                                })}
                            </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default TreatmentGallery;