import firebase from "firebase/app";  
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"



const app = firebase.initializeApp({







  apiKey: "AIzaSyAR8nedfGep_btZnIiJu3ejErnwGQw2JjM",

  authDomain: "msc-guelph.firebaseapp.com",

  projectId: "msc-guelph",

  storageBucket: "msc-guelph.appspot.com",

  messagingSenderId: "81531789941",

  appId: "1:81531789941:web:3e4acfe2028cac58980e3c"



  
  });
  

    //export const myTimestamp = firebase.firestore.Timestamp.fromDate(new Date());
    
   
    export const auth = app.auth();
  
    export const db= app.firestore();
    export const storageRef = firebase.storage();
  export default app;