import './App.css';
import temp from './images/temp.jpg';
import Dashboard from './Backend-components/Dashboard';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import { Route } from 'react-router';
import Login from './Backend-components/Login';
import FrontEndMain from './components/FrontEndMain';
import ManageFooter from './Backend-components/ManageFooter';
import DoctorManagement from './Backend-components/DoctorManagement';
import GalleryManagement from './Backend-components/GalleryManagement';
import GalleryMain from './components/GalleryMain';
import DoctorsMain from './components/DoctorsMain';
import TreatmentManagement from './Backend-components/TreatmentManagement';
import ShowTreatment from './components/ShowTreatment';
import TreatmentsMain from './components/TreatmentsMain';
import { useEffect } from 'react';
import TreatmentGallery from './Backend-components/TreatmentaGallery';

function App() {
  useEffect (()=>{
    document.title="Welcome to MSC Clinic";
  },[])

  
  return (
   
  
      <AuthProvider>
        <PrivateRoute path="/manage" component={Dashboard} />
        <PrivateRoute path="/footer-management" component={ManageFooter} />
        <PrivateRoute path="/doctor-management" component={DoctorManagement} />
        <PrivateRoute path="/treatment-management" component={TreatmentManagement} />
        <PrivateRoute path="/gallery-management" component={GalleryManagement} />
        {/* <PrivateRoute path="/users" component={Users} /> */}

        <Route path="/login" component={Login} />
        <Route path="/photo-gallery" component={GalleryMain} />
        <Route path="/doctors" component={DoctorsMain} />
        <Route path="/show-treatment/:id" component={ShowTreatment} />
        <Route path="/treatment-photo/:id" component={TreatmentGallery} />
        <Route path="/treatments" component={TreatmentsMain} />
        <Route path="/" exact component={FrontEndMain} />
      </AuthProvider>


   
    
    
  );
}

export default App;
