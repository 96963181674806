import React, { useEffect } from "react";
import NavBar from './NavBar';
import Services from './Services';
import About from './About';
import Banner from './Banner';
import Footer from './Footer';
import Mission from './Mission';
import Awards from "./Awards";
import Doctors from "./Doctors";
import Sprator from "./Sprator";
import Gallery from "./Gallery";
import { DataProvider } from "./DataContext";

const FrontEndMain = ()=>{
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
    return (
        <>
      <DataProvider>
      <NavBar />
      <Banner />
      <Services />
     
     
      <Mission num="6"/>
     
      <Sprator />
      <Doctors />
      <Sprator />
      <Gallery num="5"/>
      <Footer />
      </DataProvider>
        </>
    )
}
export default FrontEndMain;