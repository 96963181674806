import React, { useEffect } from "react"
import { Route, Redirect } from "react-router-dom"

import { useAuth } from "./AuthContext"
import { auth, db } from "../src/Firebase"


export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth()



  // useEffect(()=>{
  //     const getRole= async()=>{
  //         const data = await db.collection("drivers").doc(currentUser.uid).get();
  //         const role= data.data().role;
  //         setRole(role)
  //       }
  //      getRole();

  // },[])


  return (
    <Route
      {...rest}
      render={props => {

       
        return currentUser ? <Component {...props} /> : <Redirect to="/login" />
      }}
    ></Route>
  )
}

